import { useContext, useState } from "react";
import classNames from "classnames";
import { Collapse } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MiniButton, TypographyComponent } from "gx-npm-ui";
import ExpandCollapseIcon from "./expand-collapse.icon";
import NoteIcon from "./note.icon";
import styles from "./instructions.styles.module.scss";
import { ProposalReviewContext } from "../../../../app.context";
import { Section } from "../../../../app.types";

const Instructions = () => {
  const { t } = useTranslation();
  const { selectedSection } = useContext(ProposalReviewContext);
  const [instructionsExpand, setInstructionsExpand] = useState(false);

  return (
    <div className={styles.root} id={Section.INSTRUCTIONS}>
      <TypographyComponent
        boldness="medium"
        color={selectedSection === Section.INSTRUCTIONS ? "yam" : "carbon"}
        element="h4"
      >
        {t("Instructions")}
      </TypographyComponent>
      <div className={styles.instructionsWrapper}>
        <Collapse
          className={classNames(styles.instructions, instructionsExpand ? styles.expand : styles.collapsed)}
          collapsedHeight={160}
          data-testid="instructions-collapse-container"
          in={instructionsExpand}
        >
          <ul className={styles.instructionsList}>
            <li>
              <TypographyComponent color="coal" element="span" styling="p2">
                {t("Attach the entire vendor agreement and/or ordering document (vendor paper).")}
              </TypographyComponent>
            </li>
            <li>
              <TypographyComponent color="coal" element="span" styling="p2">
                {t("Please include any relevant attachments, schedules or addendums to the proposal.")}
              </TypographyComponent>
            </li>
            <li>
              <TypographyComponent color="coal" element="span" styling="p2">
                {t(
                  "For pricing analysis, key visible components in the vendor pricing should include: currency, SKU number, description, quantity, unit price, list price, license term, special price provisions or discounts."
                )}
              </TypographyComponent>
            </li>
            <li>
              <TypographyComponent color="coal" element="span" styling="p2">
                {t(
                  "Experts are better able to advise clients on bundled licenses when bundles are broken into component parts. If your vendor is not sharing components, you may be able to request a breakdown. Experts can then suggest alternatives to reduce your costs."
                )}
              </TypographyComponent>
            </li>
            <li>
              <TypographyComponent color="coal" element="span" styling="p2">
                {t("Please submit all materials in English.")}
              </TypographyComponent>
            </li>
          </ul>
          <div className={styles.note}>
            <NoteIcon />
            <TypographyComponent boldness="medium" color="carbon" rootClassName={styles.noteDesc} styling="p2">
              {t("Note—We are unable to provide retrospective or post-mortem reviews on already signed agreements.")}
            </TypographyComponent>
          </div>
        </Collapse>
        <MiniButton
          onClick={() => {
            setInstructionsExpand(!instructionsExpand);
          }}
          rootClassName={classNames(instructionsExpand && styles.collapsed)}
        >
          <ExpandCollapseIcon />
          <TypographyComponent boldness="medium" color="defaultCta" element="p" styling="p3">
            {instructionsExpand ? t("Show less") : t("Show more")}
          </TypographyComponent>
        </MiniButton>
      </div>
    </div>
  );
};

export default Instructions;
