import { useContext, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { InlineAlert, Loader, RichTooltip, TextLink, TypographyComponent } from "gx-npm-ui";
import InfoIcon from "../info.icon";
import styles from "./documents.styles.module.scss";
import { ProposalReviewContext } from "../../../../app.context";
import { Section, Topic } from "../../../../app.types";
import DocumentsUploaded from "./documents-uploaded.component";
import DocumentFileUpload from "./document-file-upload.component";

const MAX_ALLOWED_FILESIZE_MB = 15;

const Documents = () => {
  const { t } = useTranslation();
  const {
    documentValidationError,
    documentValidationErrorMessage,
    otherCheckbox,
    pricingAnalysisCheckbox,
    proposalReviewDocs,
    selectedSection,
    termsAndConditionsReviewCheckbox,
  } = useContext(ProposalReviewContext);
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleTooltipClose = () => setIsTooltipOpen(false);
  const handleTooltipOpen = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleClickUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleOnLoad = (loading: boolean) => {
    setIsDocumentUploading(loading);
  };

  return (
    <div className={styles.root} id={Section.DOCUMENTS}>
      <div className={styles.title}>
        <TypographyComponent
          boldness="medium"
          color={selectedSection === Section.DOCUMENTS ? "yam" : "carbon"}
          element="h4"
        >
          {t("Documents")}
        </TypographyComponent>
        <span className={styles.infoTooltipWrapper}>
          <button
            aria-label={t("Documents information tooltip")}
            className={styles.infoTooltipButton}
            onClick={handleTooltipOpen}
            onKeyDown={handleTooltipOpen}
            ref={tooltipRef}
          >
            <RichTooltip
              anchor={tooltipRef.current}
              content={[
                {
                  type: "PARAGRAPH",
                  content: t(
                    "Gartner will restrict access to Proposal Review information to authorized internal experts on a need-to-know basis. We may aggregate proposal information with validated data from other sources to inform our proprietary benchmarks. Those benchmarks are aggregate data points that do not identify specific organizations or individuals."
                  ),
                },
              ]}
              footerLinkUrl={
                "https://intercom.help/gartner-buysmart-help-center/en/articles/9971906-proposal-review-coming-soon"
              }
              footerLinkText={t("Learn more")}
              open={isTooltipOpen}
              onClose={handleTooltipClose}
              popperPlacement={"right"}
              rootClassName={styles.richTooltipContainer}
              theme={"light"}
              title={t("Document security")}
            >
              <InfoIcon />
            </RichTooltip>
          </button>
        </span>
      </div>
      <div className={classNames(styles.question, styles.topics)}>
        <TypographyComponent color="coal" styling="p2">
          {t("For this vendor, please attach the following based on the topic(s) you wish to discuss:")}
          <span className={styles.required}>
            <TypographyComponent color="statusCherry" element="span" rootClassName={styles.asterisk} styling="p1">
              {"*"}
            </TypographyComponent>
            <TypographyComponent color="poisonCherry" element="span" rootClassName={styles.requiredText} styling="p3">
              {t("required")}
            </TypographyComponent>
          </span>
        </TypographyComponent>
      </div>
      {proposalReviewDocs && proposalReviewDocs.length > 0 ? (
        <ul className={styles.proposalReviewDocs}>
          {proposalReviewDocs.map((doc, index) => {
            const { parentTopic, title } = doc;

            let isRequired = false;

            if (parentTopic === Topic.PRICING_ANALYSIS && pricingAnalysisCheckbox) {
              isRequired = true;
            } else if (parentTopic === Topic.TERMS_AND_CONDITIONS && termsAndConditionsReviewCheckbox) {
              isRequired = true;
            }

            return (
              <li
                aria-label={`${parentTopic} is ${isRequired ? "required" : "not required"}`}
                className={styles.proposalReviewDoc}
                key={`proposal-review-doc-${index}`}
              >
                <TypographyComponent color="carbon" styling="p3">
                  {parentTopic}
                </TypographyComponent>
                <div className={styles.question}>
                  <TypographyComponent boldness="medium" color="carbon" styling="p3">
                    {title}
                  </TypographyComponent>
                  {isRequired && (
                    <span className={styles.required}>
                      <TypographyComponent
                        color="statusCherry"
                        element="span"
                        rootClassName={styles.asterisk}
                        styling="p1"
                      >
                        {"*"}
                      </TypographyComponent>
                    </span>
                  )}
                </div>
              </li>
            );
          })}
          <li
            aria-label={`Other is ${otherCheckbox ? "required" : "not required"}`}
            className={styles.proposalReviewDoc}
            key={`proposal-review-doc-${proposalReviewDocs.length}`}
          >
            <TypographyComponent color="carbon" styling="p3">
              {t("Other")}
            </TypographyComponent>
            <div className={styles.question}>
              <TypographyComponent boldness="medium" color="carbon" styling="p3">
                {t("Current, draft, or fully executable order form/document")}
              </TypographyComponent>
              {otherCheckbox && (
                <span className={styles.required}>
                  <TypographyComponent color="statusCherry" element="span" rootClassName={styles.asterisk} styling="p1">
                    {"*"}
                  </TypographyComponent>
                </span>
              )}
            </div>
          </li>
        </ul>
      ) : (
        <div className={classNames(styles.question)}>
          <TypographyComponent boldness="medium" color="carbon" styling="p2">
            {t("Current, draft, or fully executable order form/document")}
          </TypographyComponent>
          <span className={styles.required}>
            <TypographyComponent color="statusCherry" element="span" rootClassName={styles.asterisk} styling="p1">
              {"*"}
            </TypographyComponent>
          </span>
        </div>
      )}
      {documentValidationError && (
        <div className={styles.alertWrapper}>
          <InlineAlert alertType="error" isShadowed={false} textMessage={documentValidationErrorMessage} />
        </div>
      )}
      <DocumentsUploaded />
      <div className={styles.documentUploadWrapper}>
        {isDocumentUploading ? (
          <div className={styles.documentUploadDesc}>
            <div className={styles.documentUploadingWrapper}>
              <div className={styles.documentUploadingLoaderWrapper}>
                <Loader rootClassName={styles.documentUploadingLoader} size={16} />
              </div>
              <TypographyComponent color="iron" styling="p4">
                {t("Uploading...")}
              </TypographyComponent>
            </div>
          </div>
        ) : (
          <DocumentFileUpload onLoad={handleOnLoad} ref={inputRef}>
            <div className={styles.documentUploadDesc}>
              <div className={styles.documentUploadDescRight}>
                <TypographyComponent color="carbon" rootClassName={styles.documentsUploadDropFile} styling="p3">
                  {t("Drop a file here or ")}
                </TypographyComponent>
                <span className={styles.textLinkWrapper}>
                  <TextLink onClick={handleClickUpload} text={t("choose a file")} />
                </span>
              </div>
              <TypographyComponent color="iron" rootClassName={styles.documentUploadFileTypes} styling="p4">
                {`${t("File types")} .doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf, .txt`}
              </TypographyComponent>
              <TypographyComponent color="iron" styling="p4">
                {`${t("Up to 5 files may be uploaded.")} ${t("Max file size")} ${MAX_ALLOWED_FILESIZE_MB}MB`}
              </TypographyComponent>
            </div>
          </DocumentFileUpload>
        )}
      </div>
    </div>
  );
};

export default Documents;
