import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, TypographyComponent } from "gx-npm-ui";
import styles from "./submitted-form.styles.module.scss";
import { ProposalReviewContext } from "../../app.context";

const SubmittedForm = () => {
  const { t } = useTranslation();
  const { refNumId } = useContext(ProposalReviewContext);

  const handleFormClose = () => {
    window.close();
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <TypographyComponent boldness="medium" styling="h2">
          {t("Your proposal review request has been submitted")}
        </TypographyComponent>
      </div>
      <div>
        <TypographyComponent boldness="medium" color="yam" rootClassName={styles.referenceNumber} styling="p1">
          {`${t("Your inquiry reference number")}: ${refNumId}`}
        </TypographyComponent>
        <TypographyComponent color="coal" rootClassName={styles.description} styling="p2">
          {t(
            "When ready to schedule, a Gartner Expert will be in touch with all of the details of your meeting. If you have any questions, please contact your Gartner account contact."
          )}
        </TypographyComponent>
      </div>
      <Button onClick={handleFormClose} rootClassName={styles.close}>
        {t("Close browser tab")}
      </Button>
    </div>
  );
};

export default SubmittedForm;
