import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAsyncRequest, useUserState, UUID } from "gx-npm-lib";
import { ErrorOverlayV2, GartnerFooter, SnackbarBanner } from "gx-npm-ui";
import Header from "./sections/header/header.component";
import Form from "./sections/form/form.component";
import SubmittedForm from "./sections/submitted-form/submitted-form.component";
import FormLoader from "./sections/loader/form-loader.component";
import styles from "./app.styles.module.scss";
import { ProposalReviewContext } from "./app.context";

const App = () => {
  const { isEntitledPR } = useUserState();
  const {
    initId,
    initProductId,
    isEligiblePR,
    isFormSubmitting,
    isSubmitted,
    setInitId,
    setInitProductId,
    setIsEligiblePR,
    setProductName,
    setProductImageLoc,
    setProposalReviewDocs,
    setProposalReviewTerms,
    setRefNumId,
  } = useContext(ProposalReviewContext);
  const { initiativeProductId = "", initiativeId = "" } = useParams<{
    initiativeProductId: UUID;
    initiativeId: UUID;
  }>();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setInitId(initiativeId);
    setInitProductId(initiativeProductId);
  }, [initiativeId, initiativeProductId, setInitId, setInitProductId]);

  useEffect(() => {
    if (!initId || !initProductId) {
      return;
    }

    (async () => {
      setIsLoading(true);
      try {
        const response = await getAsyncRequest(
          `/api/v2/initiatives/${initId}/products/${initProductId}/proposal-review`
        );

        if (response.status !== 200 || !response.data?.data) {
          throw new Error();
        }
        setIsEligiblePR(response.data.data.isEligiblePR);
        setProductName(response.data.data.productName);
        setProductImageLoc(response.data.data.productImageLoc);
        setProposalReviewDocs(response.data.data.proposalReviewDocs);
        setProposalReviewTerms(response.data.data.proposalReviewTerms);
        setRefNumId(response.data.data.refNumId);
      } catch (err) {
        setHasError(true);
      }
      setIsLoading(false);
    })();
  }, [
    initId,
    initProductId,
    setHasError,
    setIsEligiblePR,
    setIsLoading,
    setProductImageLoc,
    setProductName,
    setProposalReviewDocs,
    setProposalReviewTerms,
    setRefNumId,
  ]);

  const showErrorOverlay = !isLoading && (!isEligiblePR || !isEntitledPR);
  const showForm = !isLoading && isEligiblePR && isEntitledPR && !isSubmitted && !isFormSubmitting;

  return (
    <section className={styles.root} data-testid="proposal-review">
      {showErrorOverlay && <ErrorOverlayV2 statusCode={0} />}
      {showForm && (
        <Fragment>
          <Header />
          <div className={styles.form}>
            <Form />
          </div>
          <GartnerFooter />
        </Fragment>
      )}
      {isFormSubmitting && (
        <Fragment>
          <Header />
          <div className={styles.form}>
            <FormLoader />
          </div>
        </Fragment>
      )}
      {isSubmitted && (
        <Fragment>
          <Header />
          <div className={styles.form}>
            <SubmittedForm />
          </div>
          <GartnerFooter />
        </Fragment>
      )}
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} type="ERROR" />
    </section>
  );
};

export default App;
