import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);

const getWeekDate = (weeksAhead = 0) => {
  let currDate = dayjs();

  if (currDate.weekday() === 0) {
    currDate = dayjs().weekday(1);
  }

  if (currDate.weekday() === 6) {
    currDate = dayjs().weekday(8);
  }

  const monday = currDate.weekday(7 * weeksAhead + 1);
  const mondayMonth = monday.format("MMM");
  const mondayDay = monday.format("DD");

  const friday = currDate.weekday(7 * weeksAhead + 5);
  const fridayMonth = friday.format("MMM");
  const fridayDay = friday.format("DD");

  if (mondayMonth === fridayMonth) {
    return `${mondayMonth} ${mondayDay}-${fridayDay}`;
  } else {
    return `${mondayMonth} ${mondayDay} - ${fridayMonth} ${fridayDay}`;
  }
};

const getWeekDateStart = (weeksAhead = 0) => {
  let currDate = dayjs();

  if (currDate.weekday() === 0) {
    currDate = dayjs().weekday(1);
  }

  if (currDate.weekday() === 6) {
    currDate = dayjs().weekday(8);
  }

  const monday = currDate.weekday(7 * weeksAhead + 1);

  return monday.format("YYYY-MM-DD");
};

const getWeekDateEnd = (weeksAhead = 0) => {
  let currDate = dayjs();

  if (currDate.weekday() === 0) {
    currDate = dayjs().weekday(1);
  }

  if (currDate.weekday() === 6) {
    currDate = dayjs().weekday(8);
  }

  const friday = currDate.weekday(7 * weeksAhead + 5);

  return friday.format("YYYY-MM-DD");
};

export { getWeekDate, getWeekDateStart, getWeekDateEnd };
