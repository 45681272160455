import { Fragment, ReactElement, useContext, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { colorPalette } from "gx-npm-common-styles";
import { Button, Dialog, IconButton, TypographyComponent } from "gx-npm-ui";
import {
  DeleteTrashcanIcon,
  FileTypeDocIcon,
  FileTypeDocxIcon,
  FileTypePdfIcon,
  FileTypePPTIcon,
  FileTypePPTxIcon,
  FileTypeTextIcon,
  FileTypeXlsIcon,
  FileTypeXlsxIcon,
} from "gx-npm-icons";
import styles from "./documents-uploaded.styles.module.scss";
import { ProposalReviewContext } from "../../../../app.context";

const DocumentsUploaded = () => {
  const { t } = useTranslation();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState("");

  const { documents, setDocuments } = useContext(ProposalReviewContext);

  const colorPoisonCherry = colorPalette.status.poisonCherry.hex;

  const handleDeleteDialogOpen = (id: string) => {
    setDocumentToDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const handleClickClose = () => {
    setDocumentToDelete("");
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteDocument = () => {
    const filteredDocuments = documents.filter((document) => document.id !== documentToDelete);
    setDocuments(filteredDocuments);
    setIsDeleteDialogOpen(false);
  };

  const DocumentIconMap: Record<string, ReactElement> = {
    doc: <FileTypeDocIcon />,
    docx: <FileTypeDocxIcon />,
    pdf: <FileTypePdfIcon />,
    ppt: <FileTypePPTIcon />,
    pptx: <FileTypePPTxIcon />,
    txt: <FileTypeTextIcon />,
    xls: <FileTypeXlsIcon />,
    xlsx: <FileTypeXlsxIcon />,
  };

  const renderUploadedDocTypeIcon = (uploadedDocName: string) => {
    const fileExtension = uploadedDocName.split(".").pop()?.toLowerCase();
    if (!fileExtension) {
      return null;
    }
    return <span aria-label={`${fileExtension} ${t("document")}`}>{DocumentIconMap[fileExtension]}</span>;
  };

  return (
    <Fragment>
      {documents && documents.length > 0 && (
        <ul className={styles.documentUploadList}>
          {documents.map((document) => {
            const { fileName, id } = document;
            return (
              <li className={styles.documentUploadListItem} key={`document-upload-${id}`}>
                <div className={styles.documentIconWrapper}>{renderUploadedDocTypeIcon(fileName)}</div>
                <TypographyComponent boldness="medium" color="coal" styling="p3">
                  {fileName}
                </TypographyComponent>
                <IconButton
                  ariaLabel={t("Delete document")}
                  hoverFill={colorPoisonCherry}
                  onClick={() => handleDeleteDialogOpen(id)}
                  rootClassName={styles.documentUploadDeleteIcon}
                  transparentBackground
                >
                  <DeleteTrashcanIcon />
                </IconButton>
              </li>
            );
          })}
        </ul>
      )}
      <Dialog
        body={
          <TypographyComponent color="coal" styling="p2">
            {t("Are you sure you want to delete the document you uploaded?")}
          </TypographyComponent>
        }
        footer={
          <div className={styles.modalFooterButtonWrapper}>
            <Button onClick={handleClickClose} rootClassName={classNames(styles.cancelButton, "btn-tertiary")}>
              {t("Cancel")}
            </Button>
            <Button
              ariaLabel={t("Confirm delete document")}
              onClick={handleDeleteDocument}
              rootClassName="primary-destructive-btn"
            >
              {t("Delete document")}
            </Button>
          </div>
        }
        onClose={handleClickClose}
        open={isDeleteDialogOpen}
        rootClassName={styles.modalDeleteDocument}
        title={t("Delete document")}
      />
    </Fragment>
  );
};

export default DocumentsUploaded;
