import { useContext, FormEvent, Fragment, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { sanitize } from "dompurify";
import { Checkbox, TextField, TypographyComponent } from "gx-npm-ui";
import styles from "./discussion-topics.styles.module.scss";
import NoteIcon from "../instructions/note.icon";
import { ProposalReviewContext } from "../../../../app.context";
import { Section, SelectedTopic, Topic } from "../../../../app.types";

const DiscussionTopics = () => {
  const { t } = useTranslation();
  const {
    hasFormError,
    otherCheckbox,
    otherNotes,
    pricingAnalysisCheckbox,
    proposalReviewTerms,
    setHasFormError,
    setOtherCheckbox,
    setOtherNotes,
    setPricingAnalysisCheckbox,
    setSelectedTopics,
    setTermsAndConditionsClause,
    setTermsAndConditionsReviewCheckbox,
    selectedSection,
    termsAndConditionsClause,
    termsAndConditionsReviewCheckbox,
  } = useContext(ProposalReviewContext);

  const maxAllowedChars = 400;

  useEffect(() => {
    if (hasFormError) {
      return;
    }
    setHasFormError(termsAndConditionsClause.length > maxAllowedChars || otherNotes.length > maxAllowedChars);
  }, [hasFormError, otherNotes, setHasFormError, termsAndConditionsClause]);

  useEffect(() => {
    const currSelectedTopics: Array<SelectedTopic> = [];
    if (pricingAnalysisCheckbox) {
      currSelectedTopics.push({ topic: Topic.PRICING_ANALYSIS, notes: "" });
    }
    if (termsAndConditionsReviewCheckbox) {
      currSelectedTopics.push({ topic: Topic.TERMS_AND_CONDITIONS, notes: termsAndConditionsClause });
    }
    if (otherCheckbox) {
      currSelectedTopics.push({ topic: Topic.OTHER, notes: otherNotes });
    }
    setSelectedTopics(currSelectedTopics);
  }, [
    otherCheckbox,
    otherNotes,
    pricingAnalysisCheckbox,
    setSelectedTopics,
    termsAndConditionsReviewCheckbox,
    termsAndConditionsClause,
  ]);

  const handleSetClause = (event: FormEvent<HTMLInputElement>) => {
    setTermsAndConditionsClause(event.currentTarget.value);
  };

  const handleSetOtherNotes = (event: FormEvent<HTMLInputElement>) => {
    setOtherNotes(event.currentTarget.value);
  };

  return (
    <div className={styles.root} id={Section.DISCUSSION}>
      <TypographyComponent
        boldness="medium"
        color={selectedSection === Section.DISCUSSION ? "yam" : "carbon"}
        element="h4"
      >
        {t("Discussion topics")}
      </TypographyComponent>
      <div className={styles.discussionTopicsWrapper}>
        <div className={styles.question}>
          <TypographyComponent color="coal" styling="p2">
            {t("What advice or insight would you like a Gartner Expert to provide?")}
            <span className={classNames(styles.required, styles.requiredNoLeftPadding)}>
              <TypographyComponent color="statusCherry" element="span" rootClassName={styles.asterisk} styling="p1">
                {"*"}
              </TypographyComponent>
              <TypographyComponent color="poisonCherry" element="span" rootClassName={styles.requiredText} styling="p3">
                {t("select all that apply")}
              </TypographyComponent>
            </span>
          </TypographyComponent>
        </div>
        <ul className={styles.discussionTopicsList}>
          <li className={styles.discussionTopicsListItem}>
            <div className={styles.discussionTopicsListItemHeader}>
              <Checkbox
                checked={pricingAnalysisCheckbox}
                data-testid={"pricing-analysis-checkbox"}
                onChange={() => {
                  setPricingAnalysisCheckbox((prev) => !prev);
                }}
              />
              <TypographyComponent boldness="medium" color="carbon" styling="p2">
                {t("Pricing analysis")}
              </TypographyComponent>
            </div>
            <TypographyComponent color="coal" rootClassName={styles.discussionTopicsListDesc} styling="p4">
              {t(
                "Availability of pricing analysis is dependent on the existence of sufficient data. To ensure accuracy of Gartner’s advice, we combine aggregated and anonymized data from multiple sources to inform our proprietary benchmarks. In some instances, insufficient data may result in price benchmarking being unavailable."
              )}
            </TypographyComponent>
          </li>
          <li className={styles.discussionTopicsListItem}>
            <div className={styles.discussionTopicsListItemHeader}>
              <Checkbox
                checked={termsAndConditionsReviewCheckbox}
                data-testid={"terms-checkbox"}
                onChange={() => {
                  setTermsAndConditionsReviewCheckbox((prev) => !prev);
                }}
              />
              <TypographyComponent boldness="medium" color="carbon" styling="p2">
                {t("Terms and conditions review")}
              </TypographyComponent>
            </div>
            {!proposalReviewTerms ? (
              <div className={"gx-form-required-text"}>
                <TypographyComponent color="coal" rootClassName={styles.discussionTopicsListDesc} styling="p4">
                  {t(
                    "Our Expert will review your document prior to the call. However, since there is a limited time on the call, please share any areas of concern you would like the Expert to prioritize."
                  )}
                </TypographyComponent>
                <TextField
                  label={t("Clause")}
                  maxAllowedChars={maxAllowedChars}
                  multilinev2={true}
                  onChange={(e) => {
                    handleSetClause(e);
                  }}
                  required={true}
                  rootClassName={styles.textFieldClause}
                  showCharCount={true}
                  showMaxCharLabel={true}
                  showVerticalResize={true}
                  value={termsAndConditionsClause}
                  showOverflow={true}
                />
              </div>
            ) : (
              <Fragment>
                <div
                  className={styles.proposalReviewTermsRichText}
                  dangerouslySetInnerHTML={{ __html: sanitize(proposalReviewTerms) }}
                />
                <TypographyComponent
                  boldness="medium"
                  color="coal"
                  rootClassName={styles.discussionTopicsListDesc}
                  styling="p4"
                >
                  {t(
                    "If there is a specific clause you would like to request that is not listed here, please provide details."
                  )}
                </TypographyComponent>
                <TextField
                  label={t("Clause (optional)")}
                  maxAllowedChars={maxAllowedChars}
                  multilinev2={true}
                  onChange={(e) => {
                    handleSetClause(e);
                  }}
                  rootClassName={styles.textFieldClause}
                  showCharCount={true}
                  showVerticalResize={true}
                  showMaxCharLabel={true}
                  value={termsAndConditionsClause}
                  showOverflow={true}
                />
              </Fragment>
            )}
          </li>
          <li className={styles.discussionTopicsListItem}>
            <div className={styles.discussionTopicsListItemHeader}>
              <Checkbox
                checked={otherCheckbox}
                data-testid={"other-checkbox"}
                onChange={() => {
                  setOtherCheckbox((prev) => !prev);
                }}
              />
              <TypographyComponent boldness="medium" color="carbon" styling="p2">
                {t("Other")}
              </TypographyComponent>
            </div>
            {otherCheckbox && (
              <div className={classNames(styles.otherCheckboxWrapper, "gx-form-required-text")}>
                <div className={styles.note}>
                  <NoteIcon />
                  <TypographyComponent color="carbon" rootClassName={styles.noteDesc} styling="p3">
                    {t(
                      "Note—If you are looking to schedule any other type of interaction, rather than a proposal review, contact your account team."
                    )}
                  </TypographyComponent>
                </div>
                <TextField
                  aria-label={t("Notes")}
                  label={t("Notes")}
                  maxAllowedChars={maxAllowedChars}
                  multilinev2={true}
                  onChange={(e) => {
                    handleSetOtherNotes(e);
                  }}
                  required={true}
                  rootClassName={styles.textFieldNotes}
                  showCharCount={true}
                  showVerticalResize={true}
                  value={otherNotes}
                  showOverflow={true}
                />
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DiscussionTopics;
