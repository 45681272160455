import { useEffect } from "react";
import Body from "./body/body.component";
import NavBar from "./nav-bar/nav-bar.component";
import styles from "./form.styles.module.scss";

const Form = () => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.navigation}>
        <NavBar />
      </div>
      <div className={styles.body}>
        <Body />
      </div>
    </div>
  );
};

export default Form;
