import { Dispatch, SetStateAction } from "react";
import { UUID } from "gx-npm-lib";

enum Section {
  COMMENTS = "comments-section",
  DATES = "target-dates-section",
  DISCUSSION = "discussion-topics-section",
  DOCUMENTS = "documents-section",
  INSTRUCTIONS = "instructions-section",
}

enum Topic {
  PRICING_ANALYSIS = "Pricing Analysis",
  TERMS_AND_CONDITIONS = "Terms and Conditions",
  OTHER = "Other",
}

type Document = {
  id: string;
  fileName: string;
};

type ProposalReviewDocs = {
  parentTopic: string;
  title: string;
};

type SelectedTopic = {
  topic: Topic;
  notes: string;
};

type ProposalReviewContextValue = {
  comments: string;
  contractSignDate: string;
  documents: Document[];
  hasPreferredExpertSpeakDate: boolean;
  initId: UUID;
  initProductId: UUID;
  isEligiblePR: boolean;
  isFormSubmitting: boolean;
  isSubmitted: boolean;
  otherCheckbox: boolean;
  preferredExpertSpeakDate: string;
  preferredExpertSpeakDateIndex: number;
  preferredExpertSpeakDateStart: string;
  preferredExpertSpeakDateEnd: string;
  pricingAnalysisCheckbox: boolean;
  productImageLoc: string;
  productName: string;
  proposalReviewDocs: ProposalReviewDocs[];
  proposalReviewTerms: string;
  refNumId: string;
  selectedSection: Section;
  selectedTopics: SelectedTopic[];
  termsAndConditionsReviewCheckbox: boolean;
  setComments: Dispatch<SetStateAction<string>>;
  setDocuments: Dispatch<SetStateAction<Array<Document>>>;
  setContractSignDate: Dispatch<SetStateAction<string>>;
  setHasPreferredExpertSpeakDate: Dispatch<SetStateAction<boolean>>;
  setInitId: Dispatch<SetStateAction<UUID>>;
  setInitProductId: Dispatch<SetStateAction<UUID>>;
  setIsEligiblePR: Dispatch<SetStateAction<boolean>>;
  setIsFormSubmitting: Dispatch<SetStateAction<boolean>>;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
  setOtherCheckbox: Dispatch<SetStateAction<boolean>>;
  setPreferredExpertSpeakDate: Dispatch<SetStateAction<string>>;
  setPreferredExpertSpeakDateIndex: Dispatch<SetStateAction<number>>;
  setPreferredExpertSpeakDateStart: Dispatch<SetStateAction<string>>;
  setPreferredExpertSpeakDateEnd: Dispatch<SetStateAction<string>>;
  setPricingAnalysisCheckbox: Dispatch<SetStateAction<boolean>>;
  setProductImageLoc: Dispatch<SetStateAction<string>>;
  setProductName: Dispatch<SetStateAction<string>>;
  setProposalReviewDocs: Dispatch<SetStateAction<Array<ProposalReviewDocs>>>;
  setProposalReviewTerms: Dispatch<SetStateAction<string>>;
  setRefNumId: Dispatch<SetStateAction<string>>;
  setSelectedSection: Dispatch<SetStateAction<Section>>;
  setSelectedTopics: Dispatch<SetStateAction<Array<SelectedTopic>>>;
  setTermsAndConditionsReviewCheckbox: Dispatch<SetStateAction<boolean>>;
};

export { Document, ProposalReviewDocs, ProposalReviewContextValue, Section, SelectedTopic, Topic };
