import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { RichTooltip, TypographyComponent, TypographyStyling } from "gx-npm-ui";
import { commonTheme } from "gx-npm-common-styles";
import Comments from "./comments/comments.component";
import Documents from "./documents/documents.component";
import DiscussionTopics from "./discussion-topics/discussion-topics.component";
import Instructions from "./instructions/instructions.component";
import Submit from "./submit/submit.component";
import TargetDates from "./target-dates/target-dates.component";
import InfoIcon from "./info.icon";
import styles from "./body.styles.module.scss";
import { ProposalReviewContext } from "../../../app.context";

const Body = () => {
  const { t } = useTranslation();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [titleStyling, setTitleStyling] = useState<TypographyStyling>("h2");
  const { productImageLoc, productName } = useContext(ProposalReviewContext);

  const tooltipRef = useRef<HTMLButtonElement>(null);

  const handleTooltipClose = () => setIsTooltipOpen(false);
  const handleTooltipOpen = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleResize = useCallback(() => {
    const { lg } = commonTheme.breakpoints.values;
    const width = window.innerWidth;

    if (width < lg) {
      setTitleStyling("h3");
    } else if (width >= lg) {
      setTitleStyling("h2");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <section className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          <TypographyComponent color="carbon" element="h2" styling={titleStyling}>
            {t("Gartner Proposal Review")}
          </TypographyComponent>
          <div className={styles.infoTooltipWrapper}>
            <button
              aria-label={t("Gartner Proposal Review information tooltip")}
              className={styles.infoTooltipButton}
              onClick={handleTooltipOpen}
              onKeyDown={handleTooltipOpen}
              ref={tooltipRef}
            >
              <RichTooltip
                anchor={tooltipRef.current}
                content={[
                  {
                    type: "PARAGRAPH",
                    content: t(
                      "A Proposal Review is a 30-minute session between you and a Gartner Expert to review a vendor's proposal prior to signature."
                    ),
                  },
                ]}
                footerLinkUrl={
                  "https://intercom.help/gartner-buysmart-help-center/en/articles/9971906-proposal-review-coming-soon"
                }
                footerLinkText={t("Learn more")}
                open={isTooltipOpen}
                onClose={handleTooltipClose}
                popperPlacement={"right"}
                rootClassName={styles.richTooltipContainer}
                theme={"light"}
                title={t("Gartner Proposal Review")}
              >
                <InfoIcon />
              </RichTooltip>
            </button>
          </div>
        </div>
        <div className={styles.productWrapper}>
          <img
            alt={productName}
            className={classNames(styles.productImage, !productImageLoc && styles.noImage)}
            src={productImageLoc}
          />
          <TypographyComponent boldness="medium" color="coal" styling="p5" rootClassName={styles.productName}>
            {productName}
          </TypographyComponent>
        </div>
      </div>
      <div className={styles.content}>
        <Instructions />
        <TargetDates />
        <DiscussionTopics />
        <Documents />
        <Comments />
        <Submit />
      </div>
    </section>
  );
};

export default Body;
