import React, { Fragment, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { postAsyncRequest, putAsyncRequest } from "gx-npm-lib";
import { SnackbarBanner } from "gx-npm-ui";
import { ProposalReviewContext } from "../../../../app.context";
import styles from "./document-file-upload.styles.module.scss";

const MAX_FILE_SIZE = 1024 * 1024 * 15;

type FileUploadProps = {
  children?: Array<ReactNode> | ReactNode | number | string;
  onLoad?: (loading: boolean) => void;
};

const DocumentFileUpload = React.forwardRef<HTMLInputElement, FileUploadProps>(
  ({ children, onLoad = () => {} }, ref) => {
    const { t } = useTranslation();
    const { documents, initId, initProductId, setDocuments } = useContext(ProposalReviewContext);

    const [dragOver, setDragOver] = useState(false);
    const [error, setError] = useState(false);

    const dropRef = useRef<HTMLDivElement>(null);

    const handleFileUpload = useCallback(
      async (fileObj: File) => {
        const fileName = fileObj.name;

        if (!fileObj) {
          setError(true);
          return;
        }

        if (fileObj.size > MAX_FILE_SIZE) {
          setError(true);
          return;
        }

        onLoad(true);

        const url = `/api/v2/initiatives/${initId}/products/${initProductId}/proposal-review/upload`;
        const postResponse = await postAsyncRequest(url, { fileName });

        if (postResponse.status !== 201 || !postResponse.data?.data?.signedUrl) {
          onLoad(false);
          setError(true);
          return;
        }

        const { id, signedUrl } = postResponse.data.data;

        try {
          const { type } = fileObj;
          const reader = new FileReader();

          reader.onload = async (progressEvent) => {
            const isValidEvent = typeof progressEvent?.target?.result === "string";
            const binary = window.atob(isValidEvent ? progressEvent.target.result.split(",")[1] : "");

            const array = [];

            for (let idx = 0; idx < binary.length; idx++) {
              array.push(binary.charCodeAt(idx));
            }

            const blobData = new Blob([new Uint8Array(array)], { type });
            const requestConfig = { headers: { "Content-Type": type } };
            const response = await putAsyncRequest(signedUrl, blobData, requestConfig);

            if (response.status === 200) {
              setDocuments([...documents, { id, fileName }]);
            } else {
              setError(true);
            }

            onLoad(false);
          };
          reader.readAsDataURL(fileObj);
        } catch (err) {
          onLoad(false);
          setError(true);
        }
      },
      [documents, initId, initProductId, onLoad, setDocuments, setError]
    );

    const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
      const files = (e.target as HTMLInputElement).files;
      const fileObj = files?.[0];
      if (fileObj) {
        handleFileUpload(fileObj);
      }
    };

    useEffect(() => {
      let dragDropEnterLeaveCounter = 0;

      const handleDragEnter = () => {
        dragDropEnterLeaveCounter = dragDropEnterLeaveCounter + 1;
        setDragOver(true);
      };
      const handleDragLeave = () => {
        dragDropEnterLeaveCounter = dragDropEnterLeaveCounter - 1;
        if (dragDropEnterLeaveCounter === 0) {
          setDragOver(false);
        }
      };
      const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
      };

      const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        dragDropEnterLeaveCounter = 0;
        setDragOver(false);

        const fileObj = e.dataTransfer?.files?.[0];
        if (fileObj) {
          handleFileUpload(fileObj);
        }
      };

      const dragCurrRef = dropRef.current;
      if (dragCurrRef) {
        dragCurrRef.addEventListener("dragenter", handleDragEnter);
        dragCurrRef.addEventListener("dragleave", handleDragLeave);
        dragCurrRef.addEventListener("dragover", handleDragOver);
        dragCurrRef.addEventListener("drop", handleDrop);
      }
      return () => {
        if (dragCurrRef) {
          dragCurrRef.removeEventListener("dragenter", handleDragEnter);
          dragCurrRef.removeEventListener("dragleave", handleDragLeave);
          dragCurrRef.removeEventListener("dragover", handleDragOver);
          dragCurrRef.removeEventListener("drop", handleDrop);
        }
      };
    }, [handleFileUpload]);

    return (
      <Fragment>
        <div
          aria-label={t("file drop area")}
          className={classNames(styles.root, dragOver && styles.dragOver)}
          ref={dropRef}
        >
          {children}
          <input
            accept=".doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx,.txt"
            onChange={handleFileChange}
            ref={ref}
            style={{ display: "none" }}
            type="file"
          />
        </div>
        <SnackbarBanner isDefaultErrorMessage={true} isOpen={error} setIsOpen={setError} type="ERROR" />
      </Fragment>
    );
  }
);

export default DocumentFileUpload;
