import { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PopoverMenu, TypographyComponent, XDatePicker } from "gx-npm-ui";
import styles from "./target-dates.styles.module.scss";
import { ProposalReviewContext } from "../../../../app.context";
import { Section } from "../../../../app.types";
import { getWeekDate, getWeekDateStart, getWeekDateEnd } from "./target-dates.lib";

const TargetDates = () => {
  const { t } = useTranslation();
  const {
    contractSignDate,
    preferredExpertSpeakDateIndex,
    selectedSection,
    setContractSignDate,
    setHasPreferredExpertSpeakDate,
    setPreferredExpertSpeakDate,
    setPreferredExpertSpeakDateIndex,
    setPreferredExpertSpeakDateStart,
    setPreferredExpertSpeakDateEnd,
  } = useContext(ProposalReviewContext);
  const DEFAULT_DATE = "1970-01-01";

  const expertDates = [
    { index: 1, name: `${t("This week")} (${getWeekDate()})` },
    { index: 2, name: `${t("Next week")} (${getWeekDate(1)})` },
    { index: 3, name: `${t("2 weeks from now")} (${getWeekDate(2)})` },
    { index: 4, name: t("No preference") },
  ];

  const handleSignatureDateChange = (value: { isValid: () => never; toISOString: () => never }) => {
    const formattedDate = value && value.isValid() ? value.toISOString() : DEFAULT_DATE;
    setContractSignDate(formattedDate);
  };

  const handleExpertDateClick = (index: number) => {
    setPreferredExpertSpeakDateIndex(index);

    if (index >= 1 && index <= 3) {
      setHasPreferredExpertSpeakDate(true);
      const weeksAhead = index - 1;
      setPreferredExpertSpeakDate(getWeekDate(weeksAhead));
      setPreferredExpertSpeakDateStart(getWeekDateStart(weeksAhead));
      setPreferredExpertSpeakDateEnd(getWeekDateEnd(weeksAhead));
    } else if (index === 4) {
      setHasPreferredExpertSpeakDate(false);
      setPreferredExpertSpeakDate("");
      setPreferredExpertSpeakDateStart("");
      setPreferredExpertSpeakDateEnd("");
    }
  };

  const tomorrowsDate = new Date();
  tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);

  return (
    <div className={styles.root} id={Section.DATES}>
      <TypographyComponent boldness="medium" color={selectedSection === Section.DATES ? "yam" : "carbon"} element="h4">
        {t("Target dates")}
      </TypographyComponent>
      <div className={styles.targetDatesWrapper}>
        <div className={styles.question}>
          <TypographyComponent color="coal" styling="p2">
            {t("When do you plan to sign your contract with the vendor?")}
          </TypographyComponent>
          <div className={styles.required}>
            <TypographyComponent color="statusCherry" rootClassName={styles.asterisk} styling="p1">
              {"*"}
            </TypographyComponent>
            <TypographyComponent color="poisonCherry" rootClassName={styles.requiredText} styling="p3">
              {t("required")}
            </TypographyComponent>
          </div>
        </div>

        <XDatePicker
          minDate={tomorrowsDate}
          onChange={handleSignatureDateChange}
          placeholder={t("Add expected signature date")}
          rootClassName={styles.signatureDate}
          popperRootClassName={styles.signatureDatePopper}
          showClearButton={true}
          showIcon={true}
          showToolTip={false}
          value={contractSignDate === DEFAULT_DATE ? null : contractSignDate}
        />

        <div className={styles.question}>
          <TypographyComponent color="coal" styling="p2">
            {t("When would you like to speak with an expert?")}
          </TypographyComponent>
          <div className={styles.required}>
            <TypographyComponent color="statusCherry" rootClassName={styles.asterisk} styling="p1">
              {"*"}
            </TypographyComponent>
            <TypographyComponent color="poisonCherry" rootClassName={styles.requiredText} styling="p3">
              {t("required")}
            </TypographyComponent>
          </div>
        </div>
        <TypographyComponent color="coal" styling="p4">
          {t("Available dates will be sent to you via email for selection based on your request here.")}
        </TypographyComponent>
        <PopoverMenu
          menuItems={expertDates}
          noCurrentSelectionText={t("Select an option")}
          onClick={(event) => handleExpertDateClick(event)}
          selectedIndex={preferredExpertSpeakDateIndex}
          iconType={"arrowDynamic"}
          isDynamicWidth={false}
          rootClassName={classNames(
            styles.expertDate,
            preferredExpertSpeakDateIndex === 0 && styles.expertDateNotSelected
          )}
          useIconButton={true}
        />
      </div>
    </div>
  );
};

export default TargetDates;
