import { createContext, ReactNode, useState } from "react";
import { UUID } from "gx-npm-lib";
import { Document, ProposalReviewDocs, ProposalReviewContextValue, Section, SelectedTopic } from "./app.types";

const ProposalReviewContext = createContext<ProposalReviewContextValue>({} as ProposalReviewContextValue);
const ProposalReviewContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [comments, setComments] = useState<string>("");
  const [contractSignDate, setContractSignDate] = useState<string>("");
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [hasPreferredExpertSpeakDate, setHasPreferredExpertSpeakDate] = useState<boolean>(false);
  const [initId, setInitId] = useState<UUID>("");
  const [initProductId, setInitProductId] = useState<UUID>("");
  const [isEligiblePR, setIsEligiblePR] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [otherCheckbox, setOtherCheckbox] = useState<boolean>(false);
  const [preferredExpertSpeakDate, setPreferredExpertSpeakDate] = useState<string>("");
  const [preferredExpertSpeakDateIndex, setPreferredExpertSpeakDateIndex] = useState<number>(0);
  const [preferredExpertSpeakDateStart, setPreferredExpertSpeakDateStart] = useState<string>("");
  const [preferredExpertSpeakDateEnd, setPreferredExpertSpeakDateEnd] = useState<string>("");
  const [productImageLoc, setProductImageLoc] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [proposalReviewDocs, setProposalReviewDocs] = useState<Array<ProposalReviewDocs>>([]);
  const [pricingAnalysisCheckbox, setPricingAnalysisCheckbox] = useState<boolean>(false);
  const [proposalReviewTerms, setProposalReviewTerms] = useState<string>("");
  const [refNumId, setRefNumId] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<Section>(Section.INSTRUCTIONS);
  const [selectedTopics, setSelectedTopics] = useState<Array<SelectedTopic>>([]);
  const [termsAndConditionsReviewCheckbox, setTermsAndConditionsReviewCheckbox] = useState<boolean>(false);

  const contextValue = {
    comments,
    contractSignDate,
    documents,
    hasPreferredExpertSpeakDate,
    initId,
    initProductId,
    isEligiblePR,
    isFormSubmitting,
    isSubmitted,
    otherCheckbox,
    preferredExpertSpeakDate,
    preferredExpertSpeakDateIndex,
    preferredExpertSpeakDateStart,
    preferredExpertSpeakDateEnd,
    pricingAnalysisCheckbox,
    productImageLoc,
    productName,
    proposalReviewDocs,
    proposalReviewTerms,
    refNumId,
    selectedSection,
    selectedTopics,
    termsAndConditionsReviewCheckbox,
    setComments,
    setContractSignDate,
    setDocuments,
    setHasPreferredExpertSpeakDate,
    setInitId,
    setInitProductId,
    setIsEligiblePR,
    setIsFormSubmitting,
    setIsSubmitted,
    setOtherCheckbox,
    setPreferredExpertSpeakDate,
    setPreferredExpertSpeakDateIndex,
    setPreferredExpertSpeakDateStart,
    setPreferredExpertSpeakDateEnd,
    setPricingAnalysisCheckbox,
    setProductImageLoc,
    setProductName,
    setProposalReviewDocs,
    setProposalReviewTerms,
    setRefNumId,
    setSelectedSection,
    setSelectedTopics,
    setTermsAndConditionsReviewCheckbox,
  };

  return <ProposalReviewContext.Provider value={contextValue}>{props.children}</ProposalReviewContext.Provider>;
};

export { ProposalReviewContext, ProposalReviewContextProvider };
